<template>
  <div class="subpage">
    <div class="title">{{ $route.meta }}</div>
    <div class="content">
      <router-link v-for="(item, i) in list" :to="{ path: '/college', query: { cid: item.id } }" :key="i" target="_blank" class="college">
        <el-row>
          <el-col :span="4">
            <el-avatar v-if="item.logo" :size="110" :src="item.logo" />
            <el-avatar v-else :size="110" :src="circleUrl" />
          </el-col>
          <el-col :span="17">
            <p class="row-name">
              <span class="name">{{ item.name }}</span>
              <span v-if="item.school_hot > 0" class="view"><i class="iconfont icon-huo" />{{ item.school_hot }}w</span>
            </p>
            <p class="row">
              <span v-for="(tip, i) in item.school_tip_id" :key="i" class="tag">{{
              tip
            }}</span>
            </p>
            <p class="row info">
              <span>类型：{{ item.school_type }}</span><span v-if="item.school_belong">隶属：{{ item.school_belong }}</span><span>性质：{{ item.nature == 1 ? "公办" : "民办" }}</span><span>城市：{{ item.address }}</span>
            </p>
          </el-col>
          <el-col :span="3">
            <div class="guanzhu" @click.stop.prevent="attention(item, i)">
              取消关注
            </div>
          </el-col>
        </el-row>
      </router-link>
      <div class="pagination">
        <el-pagination :page-size="pageSize" :total="total" class="page-box" background layout="prev, pager, next" hide-on-single-page @current-change="pageChange" />
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "AttCollege",
  data() {
    return {
      circleUrl: require("@/assets/demo/college.png"),
      list: [],
      pageNum: 1,
      pageSize: 6,
      total: 0,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    attention(item, i) {
      this.$fecth
        .post("user_info/addAttention", {
          id: item.id,
          type: 0,
        })
        .then(() => {
          this.list.splice(i, 1);
          this.$message({
            offset: 400,
            showClose: true,
            message: "取消关注",
            type: "success",
          });
        });
    },
    getList() {
      this.$fecth
        .post("user_info/getAttentionList", {
          type: 0,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        })
        .then((res) => {
          this.list = res.list;
          this.total = res.count;
        });
    },
    pageChange(page) {
      this.pageNum = page;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.subpage {
  background-color: #ffffff;
  .title {
    font-size: 16px;
    height: 50px;
    line-height: 57px;
    padding: 0 30px;
    border-bottom: #ddd solid 1px;
  }
  .content {
    padding: 40px;
    min-height: 430px;
  }
  .college {
    display: block;
    border-radius: 6px;
    padding: 20px 0;
    background-color: #ffffff;
    border-bottom: 1px solid #f2f2f2;
    .row-name {
      height: 50px;
      line-height: 30px;
      span {
        margin-right: 30px;
      }
      .name {
        font-size: 24px;
        color: #757575;
      }

      .view {
        color: #ea6f62;
        font-size: 13px;

        .iconfont {
          color: #ea6f62;
          font-size: 22px;
        }
      }
    }
    .row {
      height: 30px;
      line-height: 30px;

      span {
        margin-right: 30px;
      }

      &.info {
        color: #b8b8b8;
      }

      .tag {
        padding: 5px 20px;
        color: #fff;
        font-size: 12px;
        border-radius: 40px;
        background-color: #fc9053;
      }
    }

    .guanzhu {
      border: 1px solid #8c939d;
      color: #8c939d;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 50px;
      margin-top: 40px;
    }
  }
  .pagination {
    padding: 40px 0;

    .page-box {
      text-align: center;
    }
  }
}
</style>
